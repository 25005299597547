import React, { ReactNode, useEffect, useState } from 'react';
import { AreaClick, Content } from './styles';
import api from 'services/emeserver/api';
import authToken from 'services/emeserver/authToken';
import {
  Agent,
  CitiesWithEnterprises,
  ContactsProps,
  RequestCreateOrUpdateContact,
} from 'utils/InterFaces';
import { Modal, useModal } from 'components/Modal/ReportModal';

interface Props {
  children?: ReactNode;
  agents: Agent[];
  listEnterprise: CitiesWithEnterprises[];
  handleShowReportModal: () => void;
  showModalReports: boolean;
}

const ReportsModal = (props: Props) => {
  const [contactData, setContactData] = useState([]);

  const handleUpdateStatus = async (id: number) => {
    try {
      const update = await api.put(
        `/contact/status/${id}`,
        {},
        { headers: { Authorization: authToken.token } },
      );

      return update;
    } catch (error) {
      return error.response;
    }
  };

  const isShowing = props.showModalReports;
  const toggle = props.handleShowReportModal;

  return (
    <React.Fragment>
      <Modal
        headerText="Relatórios Indique um amigo"
        isShown={isShowing}
        hide={toggle}
      />
    </React.Fragment>
  );
};

export default ReportsModal;
