import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';

import ModalElement from '../Modal';

import more_icon from '../../assets/more-icon.svg';
import date_icon from '../../assets/date.svg';
import cardsIcon from '../../assets/cards-icon.svg';
import automation_waiting_image from '../../assets/automation_waiting.png';
import campaignParticipantIcon from '../../assets/campaign_participant_icon.svg';
import notContact from '../../assets/nao-contatar_icon.svg';

import { capitalizeFirstLetter } from 'utils/TextFormatter';

import {
  CardStyle,
  Score,
  Body,
  MultipleCalls,
  IsMostRecentBadge,
  IsMostRecentBox,
  Automation_waiting,
  CampaignParticipant,
} from './styles';

import {
  dataItens,
  dataEnterprisesGroup,
  dataStatusLog,
} from 'components/shared/dataItems';
import { Agent, Data, DataEnterprise, ICallsLogs } from 'utils/InterFaces';
import api from 'services/emeserver/api';
import authToken from 'services/emeserver/authToken';

import moment from 'moment';
import { tz } from 'moment-timezone';

interface Props {
  id: number;
  score: number;
  color: string;
  nick: string;
  full_name: string;
  callType: string;
  origin: string;
  date: string;
  enterprise: string;
  callId: number;
  last_call_status: string;
  parentCallback: Function;
  agents: Agent[];
  agent_name: string;
  multiples_calls: boolean;
  is_most_recent: boolean;
  automation_waiting?: boolean;
  dateUpdated?: string;
  campaign_participant?: string;
  showModal: boolean;
  setShowModal: Function;
  justification: string;
  call_group: string;
  conversation_id?: string;
  agent_conversation_id?: string;
}

const Card: React.FC<Props> = (props) => {
  const [dataModal, setDataModal] = useState<Data>({});
  const [dataEnterprise, setDataEnterprise] = useState<DataEnterprise[]>([]);
  const [logs, setLogs] = useState<ICallsLogs[]>([]);

  const permission = sessionStorage.getItem('permission');

  const handleClose = async () => {
    setDataModal({});
    props.setShowModal(false);
    await props.parentCallback();
  };

  const handleOnHide = () => {
    setDataModal({});
    props.setShowModal(false);
  };

  const openModal = async () => {
    const dataResponseClient: Data = await dataItens(
      props.id,
      Number(props.agent_name),
    );
    const dataResponseStatusLog: ICallsLogs[] = await dataStatusLog(
      props.callId,
    );

    console.log('AQUI', dataResponseClient);
    const dataResponseEnterprise: DataEnterprise[] = await dataEnterprisesGroup(
      dataResponseClient.calls[0].group_dashboard,
    );
    setDataModal(dataResponseClient);
    setDataEnterprise(dataResponseEnterprise);
    setLogs(dataResponseStatusLog);
    props.setShowModal(true);
  };

  return (
    <>
      <CardStyle onClick={() => openModal()}>
        <Score color={props.color}>
          <p>
            Score <b>HM</b>
          </p>

          <h3>{props.score === 0 ? '-' : props.score}</h3>

          <div>
            <button onClick={openModal}>
              <ReactSVG src={more_icon} />
            </button>
          </div>
        </Score>

        <Body>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              alignSelf: 'flex-end',
            }}
          >
            {props.automation_waiting && (
              <Automation_waiting>
                <label>Automação</label>
                <img src={automation_waiting_image} />
              </Automation_waiting>
            )}

            {props.campaign_participant && (
              <CampaignParticipant>
                <img
                  src={campaignParticipantIcon}
                  title="Participante do HM Estelar"
                />
              </CampaignParticipant>
            )}

            {props.justification === 'Não deseja ser contatado' && (
              <CampaignParticipant>
                <img src={notContact} title="Não Deseja ser contatado" />
              </CampaignParticipant>
            )}

            {permission === 'corretor' && props.is_most_recent && (
              <IsMostRecentBox>
                <label>Novo</label>

                <IsMostRecentBadge />
              </IsMostRecentBox>
            )}

            <MultipleCalls>
              {props.multiples_calls && <ReactSVG src={cardsIcon} />}
            </MultipleCalls>
          </div>

          <div>
            <small>Cliente</small>
            <h3>{capitalizeFirstLetter(props.full_name)}</h3>

            <small>Empreendimento</small>

            <h3>{props.enterprise}</h3>

            <div className="groupbutton">
              {props.score > 0 && props.callType === 'Agendar visita' && (
                <div className="btn-orange">{props.callType}</div>
              )}
              {props.score > 0 && props.callType === 'Financiamento' && (
                <div className="btn-blue">{props.callType}</div>
              )}
              {props.score === 0 && props.origin === 'agenda' && (
                <div className="btn-green">{props.origin.toUpperCase()}</div>
              )}

              <div className="btn-date">
                <ReactSVG src={date_icon} />
                <span>
                  {tz(props.dateUpdated, 'America/Sao_Paulo').format(
                    'DD/MM/YYYY HH:mm',
                  )}
                </span>
              </div>
              <div className="btn-days-update">
                <span>Dias sem atualizações </span>
                <strong>
                  {' '}
                  {(
                    (new Date().getTime() -
                      new Date(props.dateUpdated).getTime()) /
                    86400000
                  ).toFixed(0)}
                </strong>
              </div>
            </div>
          </div>
        </Body>
      </CardStyle>

      {dataModal && dataModal.client && (
        <ModalElement
          show={props.showModal}
          click={handleClose}
          onHide={handleOnHide}
          item={props.id}
          agents={props.agents}
          data={dataModal}
          agent_name={props.agent_name}
          calls_logs={logs}
          last_call_status={props.last_call_status}
          last_call_id={props.callId}
          enterpriselist={dataEnterprise}
          call_group={props.call_group}
          conversation_id={props.conversation_id}
          agent_conversation_id={props.agent_conversation_id}
        />
      )}
    </>
  );
};

export default Card;
