import React, { FormEvent, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';

import {
  Button,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';

import { validateEmail } from '../../utils/validation';

import {
  removeSpecialCaracteres,
  removeSpecialCaractersFromPhoneNumber,
  cpfValidate,
} from 'utils/numberUtils';

import FormContext from 'context/FormContext';

import StepTwo from './StepTwo';
import Success from './Success';

import hmLogo from '../../assets/hm-logo.svg';

import api from '../../services/emeserver/api';

import authToken from '../../services/emeserver/authToken';

import * as S from './styles';
import { toast, ToastContainer } from 'react-toastify';

export default function RegisterLead(): JSX.Element {
  const [fields, setFields] = useState({
    full_name: '',
    phone_number: '',
    cpf: '',
    email: '',
    agent_id: '',
    enterprise_slug: '',
    call_type: '',
    origin: '',
    conversion_channel: '',
    group_dashboard: '',
  });
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [agentName, setAgentName] = useState('');

  const history = useHistory();
  const selectedAgent = JSON.parse(sessionStorage.getItem('selectedAgent'));
  const permission = sessionStorage.getItem('permission');


  async function onSubmit() {
    if (alreadyRegistered) {
      history.goBack();

      return;
    }

    const createdCallRes = await api.post(
      '/contact/create/new-contacts',
      {
        full_name: fields.full_name,
        nick_name: fields.full_name,
        cpf: fields.cpf ? fields.cpf : '',
        phone_number: fields.phone_number,
        email: fields.email,
        agent_id: fields.agent_id,
        enterprise: fields.enterprise_slug,
        call_type: fields.call_type,
        origin: fields.origin,
        conversion_channel: fields.conversion_channel,
        type: 'dashboard',
      },
      {
        headers: {
          Authorization: authToken.token,
        },
      },
    );

    if (createdCallRes.status === 200) {
      setSuccess(true);
    }
  }

  async function handleFetchRegisteredLead() {
    const groupDashboard = permission === "admin-super" ? fields.group_dashboard : selectedAgent.group_dashboard

    if(fields.phone_number) {
      setLoading(true);
      const onlyNumbers = fields.phone_number
        .replace('+55', '')
        .replace(/\D/g, '');
      const existPhoneNumber = await api.get(
        `/client/admin/findPhoneNumber?phone_number=${onlyNumbers}&group_dashboard=${groupDashboard}`,
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );

      if (existPhoneNumber.data.response_data) {
        setAgentName(existPhoneNumber.data.response_data[0].agent_name);
        setAlreadyRegistered(true);
      }
      setLoading(false);
    }

    if(fields.cpf) {
      const cpfFormat = String(fields.cpf).replace(/[^\d]/g, '');
      cpfFormat.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      setLoading(true)
      const existCpf = await api.get(
        `/client/admin/findCpf?cpf=${cpfFormat}&group_dashboard=${groupDashboard}`,
        {
          headers: {
            Authorization: authToken.token,
          },
        },
      );
  
      if (existCpf.data.response_data) {
        setAgentName(existCpf.data.response_data[0].agent_name);
        setAlreadyRegistered(true);
      }
      setLoading(false)
    }
  }

  return (
    <S.Container>
      <S.Card>
        <S.LogoContainer>
          <div className="col-md-12 text-center">
            <ReactSVG src={hmLogo} />
            <h1>Dashboard Contatos</h1>
          </div>
        </S.LogoContainer>

        {success ? (
          <Success />
        ) : (
          <S.Form>
            <FormikStepper
              initialValues={{
                phone_number: '',
                name: '',
                cpf: '',
                email: '',
                agent_id: 0,
                enterprise_slug: '',
                call_type: '',
                origin: '',
                conversion_channel: '',
              }}
              onSubmit={onSubmit}
              handleFetchRegisteredLead={handleFetchRegisteredLead}
              alreadyRegistered={alreadyRegistered}
              fields={fields}
              loading={loading}
            >
              <FormikStep label="Dados do atendimento">
                <FormContext.Provider
                  value={{
                    fields,
                    setFields,
                  }}
                >
                  <StepTwo
                    agentName={agentName}
                    alreadyRegistered={alreadyRegistered}
                    handleFetchRegisteredLead={handleFetchRegisteredLead}
                  />
                </FormContext.Provider>
              </FormikStep>
            </FormikStepper>
          </S.Form>
        )}
      </S.Card>
      <ToastContainer />
    </S.Container>
  );
}

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export function FormikStep({ children }: FormikStepProps) {
  return <>{children}</>;
}

export function FormikStepper({
  children,
  alreadyRegistered,
  fields,
  loading,
  ...props
}: any) {
  const childrenArray = React.Children.toArray(
    children,
  ) as React.ReactElement<FormikStepProps>[];
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);

  const history = useHistory();

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  function handleCloseRegisterLeadModal() {
    history.goBack();
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);

          setCompleted(true);
        } else {
          if (step === 0) {
            const { phone_number } = fields;

            const phoneNumbers =
              removeSpecialCaractersFromPhoneNumber(phone_number);

            if (phoneNumbers.length < 11) {
              return toast.error('Telefone não possui um formato válido.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }

            setTimeout(async () => {
              await props.handleFetchRegisteredLead();

              setStep((s) => s + 1);
            }, 1000);
          }
        }
      }}
    >
      {({ errors, isSubmitting }) => (
        <Form autoComplete="off">
          {step === 0 && !alreadyRegistered && (
            <h2 className="title-1 mb-4">Concluir cadastro</h2>
          )}
          <Stepper alternativeLabel activeStep={step}>
            {childrenArray.map((child, index) => (
              <Step
                key={child.props.label}
                completed={step > index || completed}
              >
                <StepLabel></StepLabel>
              </Step>
            ))}
          </Stepper>
          {currentChild}
          <div className="buttons-next">
            {step === 0 && !alreadyRegistered &&  (
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                onClick={() => handleCloseRegisterLeadModal()}
              >
                Cancelar
              </Button>
            )}

            {step > 0 && !alreadyRegistered ? (
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                onClick={() => setStep((s) => s - 1)}
              >
                Voltar
              </Button>
            ) : null}

            {!alreadyRegistered ? (
              <Button
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={isSubmitting || loading}
                variant="contained"
                color="primary"
                type="submit"
                className={step === 1 ? 'red' : 'black'}
              >
                {isSubmitting
                  ? 'Enviando'
                  : isLastStep()
                  ? 'Cadastrar'
                  : 'Próximo'}
              </Button>
            ) : (
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
                className={'red'}
                style={{ color: '#FFF' }}
              >
                Fechar
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}
